import React from 'react';
import { US_States } from './options';
import Select from 'react-select';

const StatesDropdown = props => {
  const { onChange, name, defaultValue, country } = props;
  let options = [];

  switch (country) {
    default:
    case 'US':
      options = US_States;
  }

  const triggerChange = value => {
    onChange({
      target: {
        name: name,
        value: value.value
      }
    });
  };

  const getDefaultValue = () => {
    return options.filter(option => option.value === defaultValue);
  };

  return (
    <>
      {options && (
        <Select
          classNamePrefix="country-select"
          inputProps={{ readOnly: true }}
          options={options}
          onChange={triggerChange}
          defaultValue={getDefaultValue()}
          isClearable={false}
          isSearchable={false}
        />
      )}
    </>
  );
};

export default StatesDropdown;
