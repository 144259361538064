import React from 'react';
import * as S from './UserAddress.styles';
import FunnelContext from '../../context/FunnelContext';

class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: this.props.showError,
      isEditing: false,
      value: props.value,
      validated: false,
      error_message: 'This value is required.'
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
  }

  validateInput(value) {
    if (!this.props.isRequired) {
      this.setState({
        showError: false
      });
      return;
    }
    const {
      extraObjects: {
        user: { address }
      }
    } = this.context;
    const editAddress =
      this.props.activeTab === `billing` ? address?.billing : address?.shipping;

    if (editAddress?.country === 'US') {
      if (
        this.props.name === 'zip' &&
        ((value.length <= 4 && value.length >= 1) || value.length > 5)
      ) {
        this.setState({
          showError: true,
          validated: false,
          error_message: 'ZIP should contain 5 digits.'
        });
      } else {
        this.setState({
          showError: false
        });
      }
    } else if (value.length === 0) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'This value is required.'
      });
    } else {
      this.setState({
        showError: false
      });
    }
  }

  handleChange(event) {
    let targetValue = event.target.value;
    this.setState({ value: targetValue });
    this.validateInput(targetValue);
  }

  toggleEditing(event) {
    let targetValue = event.target.value;
    this.props.onChange(this.props.name, targetValue);
    this.validateInput(targetValue);
    this.setState({ isEditing: !this.state.isEditing });
  }

  toggleFocus(evt) {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <>
        <S.InputForm
          type="text"
          maxLength={this.props.maxLength}
          name={this.props.name}
          defaultValue={this.state.value}
          onChange={this.handleChange}
          onBlur={this.toggleEditing}
          placeholder={this.props.placeholder}
          showError={this.props.isRequired && this.state.showError}
          autoComplete="none"
        />

        {this.props.isRequired && this.state.showError && (
          <S.ValidationError>{this.state.error_message}</S.ValidationError>
        )}
      </>
    );
  }
}
InputForm.contextType = FunnelContext;
export default InputForm;
