import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  margin-top: 36px;
  background-color: #fff;
`;
export const OuterBorder = styled.div`
  border: 1px solid #ccd2e3;
  border-radius: 0px 4px 4px 4px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
  padding: 15px;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -51px;
  margin-left: -16px;
`;

export const TabItem = styled.div`
  border-radius: 4px 4px 0px 0px;
  padding: 8px 12px 4px;
  font-size: 18px;
  font-family: Utopia Std;
  text-align: center;
  color: #001c72;
  width: 200px;
  border-bottom: 1px solid #ccd2e3;
  background-color: #e6e8f1;
  position: relative;
  cursor: pointer;
  &.active {
    background-color: #fff;
    border: 1px solid #ccd2e3;
    border-bottom: 1px solid #fff;
  }

  @media ${queries.sm} {
    width: 180px;
    font-size: 14px;
    padding: 10px 12px 7px;
  }
`;

export const EditDetails = styled.span`
  color: #bc2e3e;
  text-decoration: underline;
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
  font-family: Neuzeit Grotesk;
  @media ${queries.md} {
    top: 15px;
  }
  @media ${queries.sm} {
    top: 90px;
    right: 5px;
  }
`;

export const Body = styled.div`
  position: relative;
  display: block;
  min-height: 108px;
  padding-top: 25px;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-deriction: row;
  padding-right: 150px;

  @media ${queries.md} {
    padding-right: 0;
    margin-top: 20px;
  }
  @media ${queries.sm} {
    margin-top: 20px;
    padding-right: 100px;
  }
`;
export const AddressColumnOne = styled.div`
  height: 100px;
  width: 50%;
`;
export const AddressColumnTwo = styled.div`
  height: 100px;
  width: 50%;
`;

export const AddressDetail = styled.div`
  font-family: Neuzeit Grotesk;
  font-size: 14px;
  color: #001c72;
  margin-top: 5px;
`;

export const Footer = styled.div`
  min-height: 10px;
  border-top: 1px solid #ccd2e3;
  padding-top: 20px;
  @media ${queries.sm} {
    display: none;
  }
`;

export const FooterLabel = styled.div`
  font-size: 18px;
  font-family: Utopia Std;
  text-align: left;
  color: #001c72;
`;

export const FooterOptions = styled.div`
  display: flex;
  flex-deriction: row;
  padding: 10px 0;
`;

export const FooterOptionOne = styled.div`
  display: flex;
  flex-deriction: row;
  width: 50%;
`;
export const FooterOptionTwo = styled.div`
  display: flex;
  flex-deriction: row;
  width: 50%;
`;
export const ShippingOption = styled.div`
  cursor: pointer;
  display: flex;
  flex-deriction: row;

  &.mobile-options {
    display: none;
  }
  @media ${queries.sm} {
    &.mobile-options {
      display: block;
      margin-bottom: 10px;

      & .radio {
        margin-top: -5px;
      }
    }
  }
`;

export const OptionRadio = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #ccd2e3;
  border-radius: 20px;
  &.active {
    border: 7px solid #001c72;
  }

  @media ${queries.md} {
    position: absolute;
  }
`;

export const OptionLabel = styled.div`
  font-size: 14px;
  font-family: Neuzeit Grotesk;
  display: block;
  margin-top: 5px;
  margin-left: 10px;
  color: #001c72;

  @media ${queries.md} {
    margin-top: 0px;
    margin-left: 50px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  &.mb-0 {
    margin-bottom: 0;
  }
  @media ${queries.sm} {
    &.mobile-margin-top {
      margin-top: 25px;
    }
  }
`;

export const Col = styled.div`
  width: 100%;
`;

export const ColOne = styled.div`
  width: 50%;
  margin-right: 6px;
  &.prefix {
    width: 30%;
  }
`;

export const ColTwo = styled.div`
  width: 50%;
  margin-left: 6px;
  &.phone {
    width: 70%;
  }
`;

export const ColThree = styled.div`
  width: 40%;
  margin-left: 6px;
`;

export const ColPhone = styled.div`
  width: 60%;
  margin-right: 6px;
`;

export const InputContainer = styled.div`
  & .country-select__control {
    border: 1px solid #ccd2e3;
    min-height: 46px;
  }
  & .country-select__single-value {
    color: #001c72;
    font-family: Utopia Std;
    font-size: 16px;
    padding-top: 7px;
  }
  & .country-select__menu {
    color: #001c72;
    font-family: Utopia Std;
    font-size: 16px;
  }
`;

export const InputLabel = styled.div`
  color: #001c72;
  font-family: Utopia Std;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const InputForm = styled.input`
  padding: 15px 15px 10px;
  font-family: Utopia Std;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  ${({ showError }) => {
    if (showError) {
      return `
        border-color: #BC2E3E;
        outline-color: #BC2E3E;
      `;
    }
  }}
`;

export const UpdateButton = styled.button`
  border-radius: 4px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 16px;
  padding: 15px 14px 10px;
  font-family: Neuzeit Grotesk;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  letter-spacing: 2px;
  &:hover {
    background-color: #a02734;
  }
  &.clear {
    background-color: #fff;
    color: #bc2e3e;
    border: 1px solid #bc2e3e;
    &:hover {
      background-color: #f2d5d8;
    }
  }
  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
    `
      : ``;
  }}
  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;

export const ValidationError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
