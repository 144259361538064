import React, { useState, useContext } from 'react';
import * as S from './UserAddress.styles';
import FunnelContext from '../../context/FunnelContext';
import { updateAddress } from '../../utils/user';
import CountryDropdown from './CountryDropdown';
import StatesDropdown from './StatesDropdown';
import InputForm from './InputForm';

const UserAddress = props => {
  const { designVersion, updateToApi, endpointApiUrl } = props;

  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  const { tokenQuery, user } = extraObjects;
  const { address } = user ?? { address: {} };

  const [billingAsShipping, setBillingAsShipping] = useState(
    address?.billing_as_shipping ?? true
  );
  const [billingAddress, setBillingAddress] = useState(address?.billing ?? {});
  const [shippingAddress, setShippingAddress] = useState(
    address?.shipping ?? {}
  );
  const [newBillingAddress, setNewBillingAddress] = useState({});
  const [newShippingAddress, setNewShippingAddress] = useState({});
  const [activeTab, setActiveTab] = useState('billing');
  const [editDetails, setEditDetails] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [validated, setValidated] = useState({
    firstName: false,
    lastName: false,
    // phone: false,
    street: false,
    //street2: false,
    // street3: false,
    city: false,
    zip: false,
    stateProvince: false
    // country: false,
  });

  const [showError, setShowError] = useState({
    firstName: false,
    lastName: false,
    // phone: false,
    // street: false,
    street2: false,
    //street3: false,
    city: false,
    zip: false,
    stateProvince: false
    // country: false,
  });

  const validateInput = props => {
    setValidated(
      Object.assign(validated, {
        firstName: props?.firstName.length > 0,
        lastName: props?.lastName.length > 0,
        // phone: props.phone.length > 0,
        street: props?.street.length > 0,
        //street2: props.street2.length > 0,
        // street3: props.street3.length > 0,
        city: props?.city.length > 0,
        zip: props?.zip.length > 0,
        stateProvince: props?.stateProvince.length > 0
        // country: props.country.length > 0,
      })
    );

    if (props?.country === 'US') {
      setValidated(
        Object.assign(validated, {
          zip: (() => {
            return props?.zip.length === 5;
          })()
        })
      );
    }

    let notDisabled = true;
    Object.keys(validated)
      .filter(key => !['phone', 'street2', 'street3'].includes(key))
      .map(key => {
        if (!validated[key]) {
          notDisabled = false;
        }
        return key;
      });

    if (notDisabled) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const runValidation = tab => {
    const editAddress3 =
      tab === `billing` ? newBillingAddress : newShippingAddress;
    const editAddress4 = tab === `billing` ? billingAddress : shippingAddress;
    validateInput(Object.assign(editAddress4, editAddress3));
  };

  const BillingAddressContainer = () => {
    return (
      <>
        <S.AddressColumnOne>
          <S.AddressDetail>
            {billingAddress?.firstName} {billingAddress?.lastName}
          </S.AddressDetail>
          <S.AddressDetail>{billingAddress?.phone}</S.AddressDetail>
        </S.AddressColumnOne>
        <S.AddressColumnTwo>
          <S.AddressDetail>
            {billingAddress?.street}, {billingAddress?.street2},{' '}
            {billingAddress?.street3}
          </S.AddressDetail>
          <S.AddressDetail>
            {billingAddress?.city}, {billingAddress?.zip}
          </S.AddressDetail>
          <S.AddressDetail>
            {billingAddress?.stateProvince}, {billingAddress?.country}
          </S.AddressDetail>
        </S.AddressColumnTwo>
      </>
    );
  };

  const ShippingAddressContainer = () => {
    return (
      <>
        <S.AddressColumnOne>
          <S.AddressDetail>
            {shippingAddress?.firstName} {shippingAddress?.lastName}
          </S.AddressDetail>
          <S.AddressDetail>{shippingAddress?.phone}</S.AddressDetail>
        </S.AddressColumnOne>
        <S.AddressColumnTwo>
          <S.AddressDetail>
            {shippingAddress?.street}, {shippingAddress?.street2},{' '}
            {shippingAddress?.street3}
          </S.AddressDetail>
          <S.AddressDetail>
            {shippingAddress?.city}, {shippingAddress?.zip}
          </S.AddressDetail>
          <S.AddressDetail>
            {shippingAddress?.stateProvince}, {shippingAddress?.country}
          </S.AddressDetail>
        </S.AddressColumnTwo>
      </>
    );
  };

  const EditAddressContainer = () => {
    const EACeditAddress =
      activeTab === `billing` ? newBillingAddress : newShippingAddress;
    const EACeditAddress2 =
      activeTab === `billing` ? billingAddress : shippingAddress;

    const handleChange = (name, value) => {
      if (activeTab === `billing`) {
        setNewBillingAddress({
          ...newBillingAddress,
          [name]: value
        });
      } else {
        setNewShippingAddress({
          ...newShippingAddress,
          [name]: value
        });
      }

      validateInput({
        ...Object.assign(
          Object.assign(
            {},
            {
              ...EACeditAddress2,
              ...EACeditAddress
            }
          ),
          {
            [name]: value
          }
        )
      });

      setShowError({
        ...showError,
        [name]: true
      });
    };

    const handleDropdownChange = evt => {
      handleChange(evt.target.name, evt.target.value);
    };

    const triggerUpdateAddress = () => {
      if (buttonDisabled) {
        return null;
      }

      if (activeTab === `billing`) {
        setBillingAddress(Object.assign(billingAddress, newBillingAddress));
      } else {
        setShippingAddress(Object.assign(shippingAddress, newShippingAddress));
      }

      if (updateToApi) {
        if (!buttonClicked && !buttonDisabled) {
          setButtonClicked(true);
          setButtonDisabled(true);
          const finalShippingAddress = billingAsShipping
            ? billingAddress
            : shippingAddress;
          updateAddress({
            token: tokenQuery,
            apiUrl: endpointApiUrl,
            billing: billingAddress,
            shipping: finalShippingAddress,
            billingAsShipping: billingAsShipping
          }).then(() => {
            setButtonClicked(false);
            setButtonDisabled(false);
            setEditDetails(false);
          });
        }
      } else {
        setExtraObjects(
          Object.assign(extraObjects, {
            user: Object.assign(user, {
              address: {
                billing: billingAddress,
                shipping: billingAsShipping ? billingAddress : shippingAddress,
                billing_as_shipping: billingAsShipping
              }
            })
          })
        );
        setEditDetails(false);
      }
    };

    return (
      <>
        <S.Row className="mobile-margin-top">
          <S.ColOne>
            <S.InputContainer>
              <S.InputLabel>Name</S.InputLabel>
              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.firstName ?? EACeditAddress2?.firstName}
                showError={showError.firstName && !validated.firstName}
                onChange={handleChange}
                isRequired={true}
                name="firstName"
                placeholder="First Name"
              />
            </S.InputContainer>
          </S.ColOne>
          <S.ColTwo>
            <S.InputContainer>
              <S.InputLabel>Surname</S.InputLabel>
              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.lastName ?? EACeditAddress2?.lastName}
                showError={showError.lastName && !validated.lastName}
                onChange={handleChange}
                isRequired={true}
                name="lastName"
                placeholder="Last Name"
              />
            </S.InputContainer>
          </S.ColTwo>
        </S.Row>

        <S.Row>
          <S.ColOne>
            <S.InputContainer>
              <S.InputLabel>Phone Number</S.InputLabel>

              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.phone ?? EACeditAddress2?.phone}
                showError={showError.phone && !validated.phone}
                onChange={handleChange}
                isRequired={false}
                name="phone"
                placeholder="Phone Number"
              />
            </S.InputContainer>
          </S.ColOne>
          <S.ColTwo>
            <S.InputContainer>
              <S.InputLabel>Apt., Suite, Unit</S.InputLabel>

              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.street2 ?? EACeditAddress2?.street2}
                showError={showError.street2 && !validated.street2}
                onChange={handleChange}
                isRequired={false}
                name="street2"
                placeholder="Apt., Suite, Unit"
              />
            </S.InputContainer>
          </S.ColTwo>
        </S.Row>

        <S.Row>
          <S.Col>
            <S.InputContainer>
              <S.InputLabel>Street address</S.InputLabel>

              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.street ?? EACeditAddress2?.street}
                showError={showError.street && !validated.street}
                onChange={handleChange}
                isRequired={true}
                name="street"
                placeholder="Street address"
              />
            </S.InputContainer>
          </S.Col>
        </S.Row>

        <S.Row>
          <S.ColOne>
            <S.InputContainer>
              <S.InputLabel>City</S.InputLabel>

              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.city ?? EACeditAddress2?.city}
                showError={showError.city && !validated.city}
                onChange={handleChange}
                isRequired={true}
                name="city"
                placeholder="City"
              />
            </S.InputContainer>
          </S.ColOne>
          <S.ColTwo>
            <S.InputContainer>
              <S.InputLabel>Zipcode</S.InputLabel>

              <InputForm
                activeTab={activeTab}
                value={EACeditAddress?.zip ?? EACeditAddress2?.zip}
                showError={showError.zip && !validated.zip}
                onChange={handleChange}
                isRequired={true}
                name="zip"
                placeholder="Zipcode"
              />
            </S.InputContainer>
          </S.ColTwo>
        </S.Row>

        <S.Row>
          <S.ColOne>
            <S.InputContainer>
              <S.InputLabel>State / Province</S.InputLabel>

              {!['US'].includes(
                EACeditAddress?.country || EACeditAddress2?.country
              ) && (
                <InputForm
                  activeTab={activeTab}
                  value={
                    EACeditAddress?.stateProvince ??
                    EACeditAddress2?.stateProvince
                  }
                  showError={
                    showError.stateProvince && !validated.stateProvince
                  }
                  onChange={handleChange}
                  isRequired={true}
                  name="stateProvince"
                  placeholder="State / Province"
                />
              )}

              {['US'].includes(
                EACeditAddress?.country || EACeditAddress2?.country
              ) && (
                <>
                  <StatesDropdown
                    onChange={handleDropdownChange}
                    showError={
                      showError.stateProvince && !validated.stateProvince
                    }
                    name="stateProvince"
                    defaultValue={
                      EACeditAddress?.stateProvince ??
                      EACeditAddress2?.stateProvince
                    }
                  />
                  {showError.stateProvince && !validated.stateProvince && (
                    <S.ValidationError>
                      This value is required.
                    </S.ValidationError>
                  )}
                </>
              )}
            </S.InputContainer>
          </S.ColOne>
          <S.ColTwo>
            <S.InputContainer>
              <S.InputLabel>Country</S.InputLabel>
              <CountryDropdown
                showError={showError.country && !validated.country}
                name="country"
                defaultValue={
                  EACeditAddress?.country ?? EACeditAddress2?.country
                }
              />
            </S.InputContainer>
          </S.ColTwo>
        </S.Row>

        <S.Row>
          <S.ColOne>
            <S.UpdateButton
              onClick={() => setEditDetails(false)}
              className="clear"
            >
              CANCEL
            </S.UpdateButton>
          </S.ColOne>
          <S.ColTwo>
            <S.UpdateButton
              buttonDisabled={buttonDisabled}
              buttonClicked={buttonClicked}
              onClick={triggerUpdateAddress}
            >
              <span>SAVE</span>
            </S.UpdateButton>
          </S.ColTwo>
        </S.Row>
      </>
    );
  };

  const changeTab = tab => {
    setActiveTab(tab);
    runValidation(tab);
  };

  return (
    <S.Container designVersion={designVersion}>
      <S.OuterBorder>
        <S.Tabs>
          <S.TabItem
            onClick={() => changeTab('billing')}
            className={activeTab === `billing` ? 'active' : ''}
          >
            Billing Details
          </S.TabItem>
          {!billingAsShipping && (
            <S.TabItem
              onClick={() => changeTab('shipping')}
              className={activeTab === `shipping` ? 'active' : ''}
            >
              Shipping Details
            </S.TabItem>
          )}
        </S.Tabs>
        <S.Body>
          <S.ShippingOption
            className="mobile-options"
            onClick={() => {
              setBillingAsShipping(true);
              changeTab('billing');
            }}
          >
            <S.OptionRadio
              className={`radio ${billingAsShipping ? 'active' : ''}`}
            ></S.OptionRadio>
            <S.OptionLabel>Same as billing address</S.OptionLabel>
          </S.ShippingOption>

          <S.ShippingOption
            className="mobile-options"
            onClick={() => setBillingAsShipping(false)}
          >
            <S.OptionRadio
              className={`radio ${billingAsShipping ? '' : 'active'}`}
            ></S.OptionRadio>
            <S.OptionLabel>Use a different address</S.OptionLabel>
          </S.ShippingOption>

          {!editDetails && (
            <S.EditDetails onClick={() => setEditDetails(true)}>
              Edit Details
            </S.EditDetails>
          )}

          {!editDetails && (
            <S.AddressContainer>
              {activeTab === `billing` && <BillingAddressContainer />}
              {activeTab === `shipping` && <ShippingAddressContainer />}
            </S.AddressContainer>
          )}

          {editDetails && <EditAddressContainer />}
        </S.Body>
        <S.Footer>
          <S.FooterLabel>Shipping Details</S.FooterLabel>
          <S.FooterOptions>
            <S.FooterOptionOne>
              <S.ShippingOption
                onClick={() => {
                  setBillingAsShipping(true);
                  changeTab('billing');
                }}
              >
                <S.OptionRadio
                  className={`${billingAsShipping ? 'active' : ''}`}
                ></S.OptionRadio>
                <S.OptionLabel>Same as billing address</S.OptionLabel>
              </S.ShippingOption>
            </S.FooterOptionOne>
            <S.FooterOptionTwo>
              <S.ShippingOption onClick={() => setBillingAsShipping(false)}>
                <S.OptionRadio
                  className={`${billingAsShipping ? '' : 'active'}`}
                ></S.OptionRadio>
                <S.OptionLabel>Use a different address</S.OptionLabel>
              </S.ShippingOption>
            </S.FooterOptionTwo>
          </S.FooterOptions>
        </S.Footer>
      </S.OuterBorder>
    </S.Container>
  );
};

export default UserAddress;
