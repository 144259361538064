import React, { useEffect } from 'react';
import axios from 'axios';
import useLocalStorage from '../../hooks/useLocalStorage';
import Select from 'react-select';

const CountryDropdown = props => {
  const { onChange, name, defaultValue } = props;
  const [countries, setCountries] = useLocalStorage('countries');

  useEffect(() => {
    if (!countries) {
      axios
        .get('https://restcountries.com/v2/all?fields=name,alpha2Code')
        .then(res => {
          setCountries(
            res.data.map(item => {
              return {
                label: item.name,
                value: item.alpha2Code
              };
            })
          );
        });
    }
  }, [countries, setCountries]);

  const triggerChange = value => {
    onChange({
      target: {
        name: name,
        value: value.value
      }
    });
  };

  const getDefaultValue = () => {
    return countries.filter(option => option.value === defaultValue);
  };

  return (
    <>
      {countries && (
        <Select
          classNamePrefix="country-select"
          inputProps={{ readOnly: true }}
          options={countries}
          onChange={triggerChange}
          defaultValue={getDefaultValue()}
          isClearable={false}
          isSearchable={false}
          isDisabled={true}
        />
      )}
    </>
  );
};

export default CountryDropdown;
